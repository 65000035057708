var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Overlay corner rounding"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCornerRounded) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v(" By default, the overlay backdrop has square corners. If the content you are wrapping has rounded corners, you can use the ")]), _c('code', [_vm._v("rounded")]), _c('span', [_vm._v(" prop to apply rounding to the overlay's corners to match the obscured content's rounded corners.")])]), _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" Toggle overlay ")]), _c('b-row', {
    staticClass: "text-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-text', [_vm._v("With rounding")]), _c('b-overlay', {
    staticClass: "d-inline-block",
    attrs: {
      "show": _vm.show,
      "rounded": "circle"
    }
  }, [_c('b-img', {
    attrs: {
      "rounded": "circle",
      "height": "300",
      "src": require('@/assets/images/banner/banner-27.jpg'),
      "alt": "Image 1"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1 mt-md-0",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-text', [_vm._v("Without rounding")]), _c('b-overlay', {
    staticClass: "d-inline-block",
    attrs: {
      "show": _vm.show
    }
  }, [_c('b-img', {
    attrs: {
      "rounded": "circle",
      "height": "300",
      "src": require('@/assets/images/banner/banner-27.jpg'),
      "alt": "Image 1"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }