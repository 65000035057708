var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Busy state input group"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBusyState) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("In this example, we obscure the input and button:")]), _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.busy,
      "rounded": "lg",
      "opacity": "0.6"
    },
    on: {
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-input-group', {
    attrs: {
      "size": "lg",
      "aria-hidden": _vm.busy ? 'true' : null
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": _vm.busy
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    ref: "button",
    attrs: {
      "disabled": _vm.busy,
      "variant": "primary"
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._v(" Do something ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }