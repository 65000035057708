var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Card with overlay"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.show,
      "rounded": "sm"
    }
  }, [_c('div', [_c('b-card-text', [_c('span', [_vm._v("BootstrapVue's custom ")]), _c('code', [_vm._v("b-overlay")]), _c('span', [_vm._v(" component is used to visually obscure a particular element or component and its content. It signals to the user of a state change within the element or component and can be used for creating loaders, warnings/alerts, prompts, and more. ")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "disabled": _vm.show,
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v(" Show overlay ")])], 1)]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" Toggle overlay ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }