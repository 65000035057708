var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Fade transition"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFade) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default, the overlay uses Bootstrap's fade transition when showing or hiding. You can disable the fade transition via adding the ")]), _c('code', [_vm._v("no-fade")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v(" <b-overlay>.")])]), _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.show,
      "rounded": "sm",
      "no-fade": ""
    }
  }, [_c('div', {
    attrs: {
      "title": "Card with overlay",
      "aria-hidden": _vm.show ? 'true' : null
    }
  }, [_c('b-card-text', [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio voluptatibus doloremque dolor nesciunt est nobis consequuntur maxime ea ipsam? Doloribus quidem doloremque ad laudantium ullam eos, consequuntur velit quas vero. ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "disabled": _vm.show,
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v(" Show overlay ")])], 1)]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" Toggle overlay ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }