var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Non-wrapping mode"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeNoWrapMode) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default, ")]), _c('code', [_vm._v("<b-overlay>")]), _c('span', [_vm._v(" wraps the content of the default slot. In some cases you may want to obscure a parent container. Use the ")]), _c('code', [_vm._v("no-wrap")]), _c('span', [_vm._v(" prop to disable rendering of the wrapping (and ignore the default slot). Note that this requires that the ancestor element that is to be obscured to have relative positioning (either via the utility class ")]), _c('code', [_vm._v("'position-relative'")]), _c('span', [_vm._v(" , or CSS style ")]), _c('code', [_vm._v("'position: relative;'")]), _c('span', [_vm._v(").")])]), _c('div', [_c('div', {
    staticClass: "position-relative p-2 bg-info"
  }, [_c('b-card-text', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. ")]), _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "title": "Card with parent overlay"
    }
  }, [_c('b-card-text', [_vm._v("Laborum consequat non elit enim exercitation cillum.")]), _c('b-card-text', [_vm._v("Click the button to toggle the overlay:")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "disabled": _vm.show,
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v(" Show overlay ")])], 1), _c('b-card-text', {
    staticClass: "text-white font-weight-bold mb-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. ")]), _c('b-overlay', {
    attrs: {
      "show": _vm.show,
      "no-wrap": ""
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" Toggle overlay ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }