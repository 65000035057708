var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('overlay-basic'), _c('overlay-backdrop'), _c('overlay-fade'), _c('overlay-spinner'), _c('overlay-corner-round'), _c('overlay-custom-content'), _c('overlay-no-wrap-mode'), _c('overlay-form'), _c('overlay-busy-state')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }